<template>
    <article class="page-cms">
        <ContainerContent v-if="showBreadcrumbs" :align-left="true" class="breadcrumbs-wrapper">
            <Breadcrumbs :breadcrumbs="breadcrumbsList" :show-last-item="true" />
        </ContainerContent>

        <HtmlLinkHandler #default="{ clickHandler }" @click="handleClickEmit($event)">
            <PageLayout :type="page_layout" @click.native="clickHandler($event)">
                <template #left>
                    <SidebarMenu v-if="!isWebViewMobile" :is-mobile="isMobile">
                        <StaticHtmlLinkHandler>
                            <StaticBlock
                                v-if="showSidebar && sidebarCmsBlock.content"
                                :content="sidebarCmsBlock.content"
                            />
                        </StaticHtmlLinkHandler>
                    </SidebarMenu>
                </template>

                <template v-if="contentJSON" #content>
                    <PageBuilderInterpreter :components-json="contentJSON" tag="div" />
                </template>

                <template v-else #content>
                    <BaseHeading tag="h1" theme="h3" class="heading">
                        {{ heading }}
                    </BaseHeading>

                    <CmsWithAccordions>
                        <div class="content" v-html="content" />
                    </CmsWithAccordions>
                </template>
            </PageLayout>
        </HtmlLinkHandler>
    </article>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';

import HtmlLinkHandler from '@core-components/molecules/HtmlLinkHandler/HtmlLinkHandler';

import { CUSTOMER_ACCOUNT_MODIVO_CLUB_NAME } from '@router/names';

import { PSB_SIDEBAR_MENU } from '@configs/sidebar';
import { META_ROBOTS_INDEX_FOLLOW } from '@configs/seo';
import MAIN_CATEGORY_URL_KEYS from '@configs/main-category';
import EXCLUDED_PAGES_PATHS from '@configs/excluded-pages-paths';
import { MODAL_TYPE_SETTINGS, MODAL_TYPE_PROVIDERS } from '@configs/modal-consents';
import {
    MODAL_CUSTOMER_ACCOUNT_NAME,
    MODAL_TYPE,
    MODAL_MODIVO_CLUB_REGISTRATION,
    MODAL_MODIVO_CLUB_REGISTRATION_STEP,
} from '@configs/modals';

import {
    MONEYTRANSFER_AB_TEST_CLASSNAME,
    MONEYTRANSFER_AB_TEST_CLASSNAME_HIDDEN,
    A_NO_MONEYTRANSFER,
    MONEYTRANSFER_AB_TEST_NAME,
} from '@configs/abtest-moneytransfer';
import { MONEYTRANSFER_AB_TEST } from '@localeConfig/keys';

import {
    LAYOUT_TYPE_1_COLUMN_BREADCRUMBS,
    LAYOUT_TYPE_2_COLUMNS_LEFT,
    LAYOUT_TYPE_2_COLUMNS_LEFT_BREADCRUMBS,
    LAYOUT_TYPE_2_COLUMNS_RIGHT,
} from '@types/CmsPage';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { LOGIN } from '@types/CustomerModal';

import CmsPageNotFoundException from '@exceptions/services/cms/CmsPageNotFoundException';

import { ASYNC_SEOMATIC_SERVICE_CONFIG } from '@async-services/seomatic/meta';

import { replaceABTestBankTransfer } from '@assets/string-utility';
import { isRejected } from '@assets/promise';
import { getUrlForSeomatic, makeSeomaticRedirect } from '@assets/seomatic';
import { getComponentsList } from '@assets/pagebuilder';

import CmsWithAccordions from '@functionals/CmsWithAccordions/CmsWithAccordions';

import BasePage from '@pages/mixins/BasePage';

import WebView from '@mixins/WebView';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';

import PageLayout from '@molecules/PageLayout/PageLayout';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import StaticHtmlLinkHandler from '@molecules/StaticHtmlLinkHandler/StaticHtmlLinkHandler';

import SidebarMenu from '@organisms/SidebarMenu/SidebarMenu';

import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import { PAGE_LAYOUT_LOADED } from '@eobuwie/nuxt-analytics/src/types/Events';

import { Breadcrumbs } from '@modivo-ui/components/Breadcrumbs/v1';

const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');
const { mapActions: mapConsentsActions } = createNamespacedHelpers('consents');
const { mapGetters: mapCustomerGetters } = createNamespacedHelpers('customer');
const { mapGetters: mapModivoClubGetters } = createNamespacedHelpers('modivoClub');

export default {
    name: 'Cms',

    components: {
        BaseHeading,
        Breadcrumbs,
        CmsWithAccordions,
        ContainerContent,
        PageLayout,
        SidebarMenu,
        StaticHtmlLinkHandler,
        StaticBlock,
        PageBuilderInterpreter,
        HtmlLinkHandler,
    },

    mixins: [BasePage(), WebView],

    provide() {
        return {
            componentsList: this.componentsList,
        };
    },

    layout: () => 'default',

    async asyncData({ app, params, error, store, req, route, redirect }) {
        const urlForSeomatic = getUrlForSeomatic(req, route);
        const { identifier } = params;
        let seomatic = null;

        if (
            Object.values(MAIN_CATEGORY_URL_KEYS).includes(identifier) ||
            Object.values(EXCLUDED_PAGES_PATHS).includes(identifier)
        ) {
            return error({
                statusCode: 404,
            });
        }

        const [cmsPagePromise, seomaticPromise] = await app.$fetchInParallel([
            app.$services.cms.getPageBuilderPage(identifier),
            app.$asyncServices.use(
                ASYNC_SEOMATIC_SERVICE_CONFIG.NAME,
                ASYNC_SEOMATIC_SERVICE_CONFIG.METHODS.GET_SEO,
                {
                    url: urlForSeomatic,
                }
            ),
            store.dispatch('cmsBlock/getCmsBlocks', {
                cmsBlocksToLoad: PSB_SIDEBAR_MENU,
            }),
        ]);

        seomatic = seomaticPromise.value;

        if (seomatic?.redirect) {
            try {
                return makeSeomaticRedirect(seomatic.redirect, redirect, route);
            } catch (err) {
                app.$errorHandler.captureError(
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'makeSeomaticRedirect',
                    },
                    {
                        urlForSeomatic,
                        seomatic,
                    }
                );
            }
        }

        if (isRejected(cmsPagePromise)) {
            const err = cmsPagePromise.reason;

            if (err instanceof CmsPageNotFoundException) {
                return error({
                    statusCode: err.statusCode,
                    message: err.message,
                });
            }

            return error({
                statusCode: 500,
                message: 'Error getting cms page.',
            });
        }

        const {
            title,
            meta_title,
            meta_description,
            content_heading,
            content,
            content_json: contentJSON,
            page_layout,
        } = cmsPagePromise?.value || {};

        let contentWithABTest = content;

        const componentsList = getComponentsList(JSON.parse(contentJSON || JSON.stringify([])));

        if (app.$configProvider.getConfig(MONEYTRANSFER_AB_TEST)) {
            if (app.$abTests?.getVariant(MONEYTRANSFER_AB_TEST_NAME) === A_NO_MONEYTRANSFER) {
                contentWithABTest = replaceABTestBankTransfer(
                    content,
                    MONEYTRANSFER_AB_TEST_CLASSNAME,
                    MONEYTRANSFER_AB_TEST_CLASSNAME_HIDDEN
                );
            }
        }

        return {
            page_layout,
            heading: seomatic?.seo?.name || content_heading,
            content: contentWithABTest,
            contentJSON,
            seomatic,
            page: {
                meta: {
                    title: meta_title || title,
                    description: meta_description,
                },
            },
            title,
            componentsList,
        };
    },

    head() {
        const { page, seomatic } = this;
        const { title: seoTitle, description: seoDescription, robots: seoRobots } =
            seomatic?.seo?.meta || {};
        const { canonical_url: seoCanonicalUrl } = seomatic?.seo || {};

        const title = seoTitle || page.meta.title;
        const description = seoDescription || page.meta.description;

        return {
            titleTemplate: titleChunk => titleChunk,
            title,
            link: [
                {
                    hid: 'canonical',
                    rel: 'canonical',
                    href: seoCanonicalUrl || this.canonicalUrl,
                },
            ],

            meta: [
                {
                    hid: 'robots',
                    name: 'robots',
                    content: seoRobots || META_ROBOTS_INDEX_FOLLOW,
                },
                {
                    hid: 'title',
                    name: 'title',
                    content: title,
                },
                {
                    hid: 'og:title',
                    property: 'og:title',
                    content: title,
                },
                {
                    hid: 'description',
                    name: 'description',
                    content: description,
                },
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content: description,
                },
            ],
        };
    },

    computed: {
        ...mapCustomerGetters(['isLoggedIn']),
        ...mapState(['isMobile']),
        ...mapCmsBlockGetters(['staticBlockById']),
        ...mapModivoClubGetters(['isModivoClubStandardMember', 'isModivoClubGoldMember']),

        sidebarCmsBlock() {
            return this.staticBlockById(PSB_SIDEBAR_MENU) || {};
        },

        showSidebar() {
            return [
                LAYOUT_TYPE_2_COLUMNS_LEFT,
                LAYOUT_TYPE_2_COLUMNS_RIGHT,
                LAYOUT_TYPE_2_COLUMNS_LEFT_BREADCRUMBS,
            ].includes(this.page_layout);
        },

        showBreadcrumbs() {
            const isBreadcrumbsLayoutType = [
                LAYOUT_TYPE_1_COLUMN_BREADCRUMBS,
                LAYOUT_TYPE_2_COLUMNS_LEFT_BREADCRUMBS,
            ].includes(this.page_layout);

            return !this.isWebViewMobile && isBreadcrumbsLayoutType;
        },

        breadcrumbsList() {
            return [
                {
                    name: this.$t('Home page'),
                    path: this.$navigationContext.getContextPath(),
                },
                {
                    name: this.title,
                },
            ];
        },
    },

    mounted() {
        this.$analytics.emit(PAGE_LAYOUT_LOADED, {
            componentsList: Object.values(this.componentsList),
        });
    },

    methods: {
        ...mapConsentsActions(['setIsModalCookieSettingsOpen', 'setIsTrustedPartnersModalOpen']),

        handleClickEmit(event) {
            const modalType = event.target.getAttribute('data-modal-type');

            if (modalType === MODAL_TYPE_SETTINGS) {
                this.setIsModalCookieSettingsOpen(true);

                return;
            }

            if (modalType === MODAL_TYPE_PROVIDERS) {
                this.setIsTrustedPartnersModalOpen(true);

                return;
            }

            if (modalType === MODAL_MODIVO_CLUB_REGISTRATION) {
                this.openModivoClubModal();
            }
        },

        openModivoClubModal() {
            if (!this.isLoggedIn) {
                this.$modals.open(MODAL_CUSTOMER_ACCOUNT_NAME, {
                    [MODAL_TYPE]: LOGIN,
                });

                return;
            }

            if (this.isModivoClubGoldMember) {
                this.$router.push({
                    name: CUSTOMER_ACCOUNT_MODIVO_CLUB_NAME,
                });

                return;
            }

            if (this.isModivoClubStandardMember) {
                this.$modals.open(MODAL_MODIVO_CLUB_REGISTRATION, {
                    [MODAL_MODIVO_CLUB_REGISTRATION_STEP]: 3,
                });

                return;
            }

            this.$modals.open(MODAL_MODIVO_CLUB_REGISTRATION, {
                [MODAL_MODIVO_CLUB_REGISTRATION_STEP]: 1,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.page-cms {
    .heading {
        @apply mt-4;
    }

    @import './assets/scss/cms/components/cms-page';

    &:deep() {
        .sidebar-menu {
            @apply mt-4;

            .static-pages-menu {
                .menu-button {
                    @apply hidden;
                }

                .menu-content {
                    .title,
                    .close {
                        @apply hidden;
                    }

                    .subtitle {
                        @apply font-semibold text-tertiary text-m leading-m;
                    }

                    .links-list {
                        @apply py-3;

                        .menu-link {
                            @apply mb-2;

                            a {
                                @apply text-text;
                            }
                        }
                    }
                }
            }

            .paragraph {
                @apply mb-3;
            }

            .text-link {
                @apply text-text;
            }

            .logout-btn {
                @apply text-ds-content-accent;
            }
        }
    }

    @screen lg {
        .heading {
            @apply text-xl leading-xl;
        }

        .breadcrumbs-wrapper {
            @apply py-ui-2;
        }

        &:deep(.sidebar-menu) {
            @apply mt-ui-0;
        }
    }
}
</style>
